import axios from "axios";
import { HOST } from "./allowedhost";

// import { returnErrors } from "./messages";
import {
  CLIENT_LIST_TO_PHARSE,
  NEXT_CLIENT_LIST_TO_PHARSE,
  CLEAR_CLIENT_LIST_TO_PHARSE,
  GET_CLIENTS_DETAIL,
  GET_CLIENTS_DETAIL_EVENT,
  CLIENT_ADD,
  CLEAR_CLIENTS,
  LOAD_CLIENTS,
  CLIENT_DELETE,
  UPDATE_CLIENT,
  CLEAR_DOUBLE_CLIENTS,
  FIND_DOUBLE_CLIENTS,
  CLIENTS_SORT_ALFABETICAL,
  CLIENTS_SORT_BY_DATE,
  GET_CLIENTS_BY_VISIT_DATE,
  CLIENTS_LOADING,
} from "./types";
import { tokenConfig } from "./auth";

export const getClientsByVisitDate =
  (startDate, endDate) => (dispatch, getState) => {
    dispatch({ type: CLIENTS_LOADING });
    axios
      .get(`http://${HOST}/api/example-view2/`, {
        params: { start_date: startDate, end_date: endDate },
        ...tokenConfig(getState),
      })
      .then((res) => {
        dispatch({
          type: GET_CLIENTS_BY_VISIT_DATE,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const getClients = (search, page) => (dispatch, getState) => {
  // dispatch({type: USER_LOADING})

  axios
    .get(
      `http://${HOST}/api/clients-list-3/?search=${search}&page=${page}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: LOAD_CLIENTS,
        payload: res.data,
      });
    });
};
export const get_clients_detail = (id) => (dispatch, getState) => {
  // dispatch({type: USER_LOADING})

  axios
    .get(`http://${HOST}/api/clients-detail/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_CLIENTS_DETAIL,
        payload: res.data,
      });
    });
};

export const get_clients_detail_event = (id) => (dispatch, getState) => {
  // dispatch({type: USER_LOADING})

  axios
    .get(`http://${HOST}/api/clients-detail/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_CLIENTS_DETAIL_EVENT,
        payload: res.data,
      });
    });
};

export const update_client = (data, id) => (dispatch, getState) => {
  // dispatch({type: USER_LOADING})

  axios
    .put(
      `http://${HOST}/api/clients-detail/${id}/`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: UPDATE_CLIENT,
        payload: res.data,
      });
    });
};

export const delete_client = (id) => (dispatch, getState) => {
  // dispatch({type: USER_LOADING})

  axios
    .delete(`http://${HOST}/api/clients-detail/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: CLIENT_DELETE,
        payload: id,
      });
    });
};

export const delete_client_list = (list) => (dispatch, getState) => {
  // dispatch({type: USER_LOADING})
  list.forEach((element) => {
    axios
      .delete(
        `http://${HOST}/api/clients-detail/${element}/`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: CLIENT_DELETE,
          payload: element,
        });
      });
  });
};

export const addClient = (client) => (dispatch, getState) => {
  // dispatch({type: USER_LOADING})
  axios
    .post(`http://${HOST}/api/clients-list/`, client, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_CLIENTS_DETAIL_EVENT,
        payload: res.data,
      });
    });
};

export const find_double_clients = (client) => (dispatch, getState) => {
  axios
    .post(
      `http://${HOST}/api/clients-check-one/`,
      client,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: FIND_DOUBLE_CLIENTS, payload: res.data });
    });
};

export const clear_double_clients = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_DOUBLE_CLIENTS });
};

export const clients_sort_date = () => (dispatch, getState) => {
  dispatch({ type: CLIENTS_SORT_BY_DATE });
};

export const clients_sort_alf = () => (dispatch, getState) => {
  dispatch({ type: CLIENTS_SORT_ALFABETICAL });
};

export const constructJSONFromPastedInput =
  (pastedInput) => (dispatch, getState) => {
    axios
      .post(
        `http://${HOST}/api/clients-list-to-check/`,
        pastedInput,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({ type: CLIENT_LIST_TO_PHARSE, payload: res.data });
      });
  };
export const nextClientToPharse = () => (dispatch, getState) => {
  dispatch({ type: NEXT_CLIENT_LIST_TO_PHARSE });
};
export const clearClientToPharse = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_CLIENT_LIST_TO_PHARSE });
};
