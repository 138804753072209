import axios from "axios";
import { HOST } from "./allowedhost";
import {
  GET_SMS_LIST,
  GET_SMS_DETAIL,
  SEND_SMS,
  SMS_LOADING,
  SMS_LOADING_DETAIL,
} from "./types";
import { tokenConfig } from "./auth";

export const getSmsList = () => (dispatch, getState) => {
  dispatch({ type: SMS_LOADING });
  axios
    .get(`http://${HOST}/api/sms-to-send-desktop/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SMS_LIST,
        payload: res.data,
      });
    });
};

export const getSmsDetail = (id) => (dispatch, getState) => {
  dispatch({ type: SMS_LOADING_DETAIL });
  axios
    .get(`http://${HOST}/api/sms-detail/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SMS_DETAIL,
        payload: res.data,
      });
    });
};

export const done = (id) => (dispatch, getState) => {
  const body = {
    checked: true,
    error: false,
  };
  axios
    .patch(`http://${HOST}/api/sms-detail/${id}/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: SEND_SMS,
        payload: res.data,
      });
    });
};

export const noDone = (id) => (dispatch, getState) => {
  const body = {
    checked: false,
    error: false,
  };
  axios
    .patch(`http://${HOST}/api/sms-detail/${id}/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: SEND_SMS,
        payload: res.data,
      });
    });
};
