import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { searchUsers, addUserToGroup } from "../actions/member";
import {
  getClients,
  get_clients_detail,
  clients_sort_alf,
  clients_sort_date,
  delete_client_list,
} from "../actions/clients";
import { getClientEvents } from "../actions/calendar";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import "../layout/Calendar.css";

// import { withRouter } from 'react-router-dom';

class Agregate extends Component {
  state = {
    search: "",
    page: 1,
    showClient: false,
    showClientDeleateList: false,
    deleateList: [],
  };
  static propTypes = {
    clientsList: PropTypes.array.isRequired,
    get_clients_detail: PropTypes.func.isRequired,
    getClients: PropTypes.func.isRequired,
    getClientEvents: PropTypes.func.isRequired,
    clients_sort_alf: PropTypes.func.isRequired,
    clients_sort_date: PropTypes.func.isRequired,
  };

  // routeChange() {
  //   let path = `/detail`;
  //   this.props.history.push(path);
  // }
  componentDidMount() {
    console.log(this.state.deleateList);
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value, page: 1 });
  pagePrev = (e) => {
    e.preventDefault();
    const { search, page } = this.state;
    this.setState({ page: this.state.page - 1 });
    this.props.getClients(search, page - 1);
  };
  pageNext = (e) => {
    e.preventDefault();
    const { search, page } = this.state;
    this.setState({ page: this.state.page + 1 });
    this.props.getClients(search, page + 1);
  };
  onSearch = (e) => {
    e.preventDefault();
    const { search, page } = this.state;
    this.props.getClients(search, page);
  };
  showModalClient = () => {
    this.setState({ showClient: true });
  };

  hideModalClient = () => {
    this.setState({ showClient: false });
  };

  showModalshowClientDeleateList = () => {
    this.setState({ showClientDeleateList: true });
  };

  hideModalshowClientDeleateList = () => {
    this.setState({ showClientDeleateList: false });
  };

  deleateListOfClients = () => {
    console.log("WOLOLO");
    this.props.delete_client_list(this.state.deleateList);
    this.setState({ deleateList: [], showClientDeleateList: false });
  };

  addorremovelistdeleate = (e) => {
    function containsObject(obj, list) {
      var i;
      for (i = 0; i < list.length; i++) {
        if (list[i] === obj) {
          return true;
        }
      }

      return false;
    }

    console.log(e.target.value);
    const c = e.target.value;
    console.log(c);
    console.log(typeof c);
    if (containsObject(c, this.state.deleateList)) {
      this.setState({
        deleateList: this.state.deleateList.filter((event) => event !== c),
      });
    } else {
      this.setState({
        deleateList: [...this.state.deleateList, c],
      });
    }
    console.log(this.state.deleateList);
  };

  render() {
    const { search, page } = this.state;

    // const {NEXT} = 'btn btn-secondary border-secondary bg-primary '+ {...next ? "disabled" : ""}
    // const {PREV} = 'btn btn-secondary border-secondary bg-primary ' + {...prev ? "disabled" : ""}
    return (
      <Fragment>
        <Modal show={this.state.showClientDeleateList}>
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              czy chesz usunąć wybranych klientów?
            </h5>

            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={this.hideModalshowClientDeleateList}
            ></button>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={this.hideModalshowClientDeleateList}
            >
              Nie
            </button>
            <button
              type="button"
              class="btn btn-success"
              onClick={this.deleateListOfClients}
            >
              Tak
            </button>
          </div>
        </Modal>

        <Modal show={this.state.showClient}>
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Dodaj Wizyte
            </h5>

            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={this.hideModalClient}
            ></button>
          </div>
          <div class="modal-body">
            <br />
            <h3>Godzina wizyty:</h3>
            <div class="card"></div>
            <br />
            <h3>Opis:</h3>
            <textarea
              type="text"
              className="form-control"
              name="description"
              // onChange={this.onChange2}
              // value={description}
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={this.hideModalClient}
            >
              Close
            </button>
            {/* <button 
                  type="button" 
                  class="btn btn-success"
                  onClick={this.Dodaj}
                  >
                  Dodaj
                </button> */}
          </div>
        </Modal>
        <div className="my-4">
          <tr>
            <td>
              <h2>Klienci:</h2>
            </td>
            <td>
              <input
                class="form-control mr-sm-2"
                type="search"
                placeholder="Szukaj"
                aria-label="Search"
                value={search}
                name="search"
                onChange={this.onChange}
              ></input>
            </td>
            <td>
              <button
                class="btn btn-outline-success my-2 my-sm-0"
                onClick={this.onSearch}
              >
                Search
              </button>
            </td>
            <td>
              <button
                className="btn btn-primary"
                onClick={() => this.props.clients_sort_alf()}
              >
                sortuj alfabetycznie
              </button>
              <button
                className="btn btn-primary"
                onClick={() => this.props.clients_sort_date()}
              >
                sortuj według wizyt
              </button>
              {this.state.deleateList.length > 0 ? (
                <button
                  className="btn btn-danger"
                  onClick={this.showModalshowClientDeleateList}
                >
                  usuń wybrane rekordy
                </button>
              ) : null}
              {/* <div class="btn-group" role="group" aria-label="Basic example">
                {this.props.clientsList.previous  ?
                <button type="button" class='btn btn-secondary border-secondary bg-primary'
                onClick={this.pagePrev}
                >Prev</button>
                : 
                <button type="button" class='btn btn-secondary disabled'
                >Prev</button>
                }
                {this.props.clientsList.next  ?
                <button type="button" class='btn btn-secondary border-secondary bg-primary'
                onClick={this.pageNext}
                >Next</button>
                :
                <button type="button" class='btn btn-secondary border-secondary disabled'
                
                >Next</button>
                }
              </div> */}
            </td>
          </tr>
          <table className="table table-hover">
            <thead>
              {/* <tr>
                <th>
                  <form class="form-inline my-2 my-lg-0">
                    <table></table>
                  </form>
                </th>
              </tr> */}
              <tr>
                <th scope="col">#</th>
                <th scope="col">Imie i Nazwisko</th>
                <th scope="col">Miejscowość</th>
                <th scope="col">Adres</th>
                <th scope="col">Nr. tel</th>
                <th scope="col">Ostatnia Wizyta</th>
                <th scope="col">Ostatnia Wizyta (Opis)</th>
                <th scope="col">-----</th>
              </tr>
            </thead>
            <tbody>
              {this.props.clientsList.results.map((item) => (
                <tr key={item.id}>
                  <td style={{ width: "2%" }}>
                    {item.powtarza ? <span class="dot"></span> : null}
                  </td>
                  <td style={{ width: "15%" }}>{item.first_name}</td>
                  <td style={{ width: "15%" }}>{item.town}</td>
                  <td style={{ width: "15%" }}>
                    {item.street} {item.nr_house}
                  </td>
                  <td style={{ width: "14%" }}>{item.tel}</td>
                  {item.client ? (
                    <td style={{ width: "15%" }}>{item.client.data_wizyty}</td>
                  ) : (
                    <td>Brak wizyt</td>
                  )}
                  {item.client ? (
                    <td style={{ width: "12%" }}>
                      {item.client.description.substring(0, 12)}
                    </td>
                  ) : (
                    <td>Brak wizyt</td>
                  )}
                  <td style={{ width: "8%" }}>
                    <Link to="/clientDetail">
                      <button
                        className="btn btn-info btn-sm"
                        onClick={() => (
                          this.props.get_clients_detail(item.id),
                          this.props.getClientEvents(item.id)
                        )}
                      >
                        Więcej
                      </button>
                    </Link>
                  </td>
                  <td style={{ width: "4%" }}>
                    <input
                      type="checkbox"
                      id={item.id}
                      name="vehicle3"
                      value={item.id}
                      onChange={this.addorremovelistdeleate}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  clientsList: state.clients.clientsList,
});

export default connect(mapStateToProps, {
  getClients,
  get_clients_detail,
  getClientEvents,
  clients_sort_alf,
  clients_sort_date,
  delete_client_list,
})(Agregate);
