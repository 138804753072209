import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSmsList, getSmsDetail, done, noDone } from "../actions/sms";
import { Modal } from "react-bootstrap";
import "../layout/Calendar.css";

class SmsList extends Component {
  state = {
    showSmsDetails: false,
    selectedSms: null,
  };

  static propTypes = {
    smsList: PropTypes.array.isRequired,
    getSmsList: PropTypes.func.isRequired,
    getSmsDetail: PropTypes.func.isRequired,
    done: PropTypes.func.isRequired,
    noDone: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getSmsList();
  }

  showModalSmsDetails = (sms) => {
    this.setState({ showSmsDetails: true, selectedSms: sms });
  };

  hideModalSmsDetails = () => {
    this.setState({ showSmsDetails: false, selectedSms: null });
  };

  handleDoneToggle = () => {
    const { selectedSms } = this.state;
    if (selectedSms.checked) {
      this.props.noDone(selectedSms.id);
    } else {
      this.props.done(selectedSms.id);
    }
    this.setState({ showSmsDetails: false });
  };

  groupSmsByDate(smsList) {
    return smsList.reduce((acc, sms) => {
      const date = sms.data_wizyty.split("T")[0]; // Assuming date format is ISO string
      if (!acc[date]) acc[date] = [];
      acc[date].push(sms);
      return acc;
    }, {});
  }

  render() {
    const { smsList } = this.props;
    const { showSmsDetails, selectedSms } = this.state;
    const groupedSms = this.groupSmsByDate(smsList);

    return (
      <Fragment>
        <Modal show={showSmsDetails}>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Szczegóły SMS-a
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={this.hideModalSmsDetails}
            ></button>
          </div>
          <div className="modal-body">
            {selectedSms && (
              <div>
                <p>
                  <strong>Id:</strong> {selectedSms.id}
                </p>
                <p>
                  <strong>Klient:</strong> {selectedSms.client.first_name}
                </p>
                <p>
                  <strong>Miasto:</strong> {selectedSms.client.town}
                </p>
                <p>
                  <strong>Ulica:</strong> {selectedSms.client.street}{" "}
                  {selectedSms.client.nr_house}
                </p>
                <p>
                  <strong>Telefon:</strong> {selectedSms.client.tel}
                </p>
                <p>
                  <strong>Data wizyty:</strong> {selectedSms.data_wizyty}
                </p>
                <p>
                  <strong>Checked:</strong>{" "}
                  {selectedSms.checked ? "Tak" : "Nie"}
                </p>
                <p>
                  <strong>Error:</strong> {selectedSms.error ? "Tak" : "Nie"}
                </p>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={this.hideModalSmsDetails}
            >
              Zamknij
            </button>
            {selectedSms && (
              <button
                type="button"
                className={`btn ${
                  selectedSms.checked ? "btn-warning" : "btn-success"
                }`}
                onClick={this.handleDoneToggle}
              >
                {selectedSms.checked
                  ? "Oznacz jako niezrobione"
                  : "Oznacz jako zrobione"}
              </button>
            )}
          </div>
        </Modal>

        <div className="my-4">
          <h2>Lista SMS-ów:</h2>
          {Object.keys(groupedSms).map((date) => (
            <div key={date} className="mb-4">
              <h4>{date}</h4>
              <div className="card p-3 bg-light">
                {groupedSms[date].map((sms) => (
                  <div
                    key={sms.id}
                    className={`card mb-2 p-2 d-flex flex-row justify-content-between align-items-center ${
                      sms.checked ? "bg-dark text-white" : "bg-light"
                    }`}
                  >
                    <div className="client-info">
                      <span className="badge rounded-circle bg-primary text-white mr-2">
                        {sms.client.first_name.charAt(0)}
                      </span>
                      <strong>{sms.client.first_name}</strong>
                      <br />
                      <small>
                        <i className="fas fa-map-marker-alt"></i>{" "}
                        {sms.client.town}
                      </small>
                      <br />
                      <small>
                        <i className="fas fa-phone"></i> {sms.client.tel}
                      </small>
                    </div>
                    <button
                      className="btn btn-info btn-sm"
                      onClick={() => this.showModalSmsDetails(sms)}
                    >
                      Szczegóły
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  smsList: state.sms.sms_list,
});

export default connect(mapStateToProps, {
  getSmsList,
  getSmsDetail,
  done,
  noDone,
})(SmsList);
