import React from "react";

const MyAlertTemplate = ({ style, options, message, close }) => (
  <div style={style} className="alertStyle">
    {console.log(message)}
    {/* {options.type === 'info' && '!'}
      {options.type === 'success' && ':)'}
      {options.type === 'error' && ':('} */}
    {message.nsg ? (
      <>
        {message.nodeimg[0] ? (
          <div
            className="img_for_alert"
            style={{ backgroundImage: `url(${message.nodeimg[0].img})` }}
          />
        ) : (
          <></>
        )}
      </>
    ) : null}
    <table style={{ float: "left" }}>
      <tr>
        <td>{message.addLead}</td>
      </tr>
      <tr>
        {message.nsg ? (
          <td>
            {message.howMany} x {message.price}zł
          </td>
        ) : null}
      </tr>
    </table>
    <button onClick={close} className="buttonStylec">
      X
    </button>
  </div>
);

export default MyAlertTemplate;
