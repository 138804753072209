// export const GET_SMS_LIST = "GET_SMS_LIST";
// export const GET_SMS_DETAIL = "GET_SMS_DETAIL";
// export const SMS_LOADING = "SMS_LOADING";
// export const SMS_LOADING_DETAIL = "SMS_LOADING_DETAIL";

// const initialState = {
//   sms_list: [],
//   sms_list_day: [],
//   sms_next: null,
//   sms_detail: null,
//   isLoading: false,
//   isLoadingDay: false,
//   isLoadingDetail: false,
//   isRefreshingSms: false,
// };

import {
  GET_SMS_LIST,
  GET_SMS_DETAIL,
  SEND_SMS,
  SMS_LOADING,
  SMS_LOADING_DETAIL,
  GET_CLIENTS_BY_VISIT_DATE,
  CLIENTS_LOADING,
} from "../actions/types";

const initialState = {
  sms_list: [],
  sms_detail: null,
  isLoading: false,
  isLoadingDetail: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SMS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SMS_LOADING_DETAIL:
      return {
        ...state,
        isLoadingDetail: true,
      };
    case GET_SMS_LIST:
      return {
        ...state,
        isLoading: false,
        sms_list: action.payload,
      };
    case GET_SMS_DETAIL:
      return {
        ...state,
        isLoadingDetail: false,
        sms_detail: action.payload,
      };
    case SEND_SMS:
      return {
        ...state,
        sms_list: state.sms_list.map((sms) =>
          sms.id === action.payload.id ? action.payload : sms
        ),
      };
    default:
      return state;
  }
}
