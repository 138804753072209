import { combineReducers } from "@reduxjs/toolkit";
import auth from "./auth";
import group from "./group";
import member from "./member";
import chat from "./chat";
import calendar from "./calendar";
import clients from "./clients";
import stoves from "./stoves";
import messages from "./messages";
import sms from "./sms";
export default combineReducers({
  auth,
  group,
  member,
  chat,
  calendar,
  clients,
  stoves,
  messages,
  sms,
});
