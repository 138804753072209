import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getClientsByVisitDate } from "../actions/clients";

class ClientsByVisitDate extends Component {
  state = {
    startDate: "",
    endDate: "",
  };

  static propTypes = {
    clients: PropTypes.array.isRequired,
    getClientsByVisitDate: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSearch = () => {
    const { startDate, endDate } = this.state;
    this.props.getClientsByVisitDate(startDate, endDate);
  };

  render() {
    const { clients, isLoading } = this.props;
    const { startDate, endDate } = this.state;

    return (
      <div className="container my-4">
        <h2>Przeglądanie wizyt klientów</h2>
        <div className="row mb-4">
          <div className="col">
            <input
              type="date"
              className="form-control"
              name="startDate"
              value={startDate}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="col">
            <input
              type="date"
              className="form-control"
              name="endDate"
              value={endDate}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="col">
            <button className="btn btn-primary" onClick={this.handleSearch}>
              Szukaj
            </button>
          </div>
        </div>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Imię</th>
                <th scope="col">Nazwisko</th>
                <th scope="col">Miasto</th>
                <th scope="col">Ulica</th>
                <th scope="col">Telefon</th>
                <th scope="col">Data ostatniej wizyty</th>
              </tr>
            </thead>
            <tbody>
              {clients.map((client, index) => (
                <tr key={client.id}>
                  <td>{index + 1}</td>
                  <td>{client.first_name}</td>
                  <td>{client.last_name}</td>
                  <td>{client.town}</td>
                  <td>
                    {client.street} {client.nr_house}
                  </td>
                  <td>{client.tel}</td>
                  {client.client ? (
                    <td style={{ width: "15%" }}>
                      {client.client.data_wizyty}
                    </td>
                  ) : (
                    <td>Brak wizyt</td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.clients.clients,
  isLoading: state.clients.isLoading,
});

export default connect(mapStateToProps, { getClientsByVisitDate })(
  ClientsByVisitDate
);
