import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getMembers } from "../actions/member";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { addClient } from "../actions/clients";
import { get_stove, add_stove } from "../actions/stoves";
import {constructJSONFromPastedInput, nextClientToPharse, clearClientToPharse, find_double_clients, clear_double_clients, get_clients_detail} from "../actions/clients";
import store from "../store";
import { Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';

const useStyles = {
  // appBarShift: {
  //   marginTop: theme.spacing(5),
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   marginLeft: drawerWidth,
  //   transition: theme.transitions.create(["margin", "width"], {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // },
  // scrolling: {
  //   overflowY: 'scroll'
  // }
  divStyle: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column-reverse",
    height: "500px",
    border: "1px solid black",
  },
  mes: {
    textAlign: "left",
  },
};
// import { withRouter } from 'react-router-dom';

class Group extends Component {
  state = {
    first_name: "",
    second_name: "",
    town: "",
    street: "",
    nr_house: "",
    tel:"",
    stove: null,
    name:"",
    pharse_in: "",
    showStove: false,
  };

  static propTypes = {
    addClient: PropTypes.func.isRequired,
    add_stove: PropTypes.func.isRequired,
    get_stove: PropTypes.func.isRequired,
    clear_double_clients: PropTypes.func.isRequired,
    find_double_clients : PropTypes.func.isRequired,
    nextClientToPharse: PropTypes.func.isRequired,
    clearClientToPharse: PropTypes.func.isRequired,
    stove_list: PropTypes.array.isRequired,
    pharseClientsList: PropTypes.array.isRequired,
    doubleClients: PropTypes.array.isRequired,
  };
  componentDidMount() {
    store.dispatch(get_stove())
    if(this.props.pharseClientsList[0]){
      const odp = this.props.pharseClientsList[0]
      
      this.setState({first_name: odp.first_name, town: odp.town , tel: odp.tel, street: odp.street, nr_house: odp.nr_house})
      this.props.find_double_clients({first_name: odp.first_name, town: odp.town , tel: odp.tel, street: odp.street, nr_house: odp.nr_house})
    }else{
      this.setState({
        first_name: "",
        town: "",
        street: "",
        nr_house: "",
        tel:"",
      });
    }
  }


  // routeChange() {
  //   let path = `/detail`;
  //   this.props.history.push(path);
  // }
  Next = e => {
    e.preventDefault()
    this.props.nextClientToPharse()
    if(this.props.pharseClientsList[1]){
      const odp = this.props.pharseClientsList[1]
      
      this.setState({first_name: odp.first_name, town: odp.town , tel: odp.tel, street: odp.street, nr_house: odp.nr_house})
      this.props.find_double_clients({first_name: odp.first_name, town: odp.town , tel: odp.tel, street: odp.street, nr_house: odp.nr_house})
    }else{
      this.props.clear_double_clients()
      this.setState({
        first_name: "",
        town: "",
        street: "",
        nr_house: "",
        tel:"",
      });
    }
  }
  onSubmit = (e) => {
    e.preventDefault();
    const { first_name, town, street, tel, nr_house,stove } = this.state;
    const client = { first_name, town, street, nr_house,stove, tel };
    this.props.addClient(client);
    // function dec(str){
    //   const lastSpaceIndex = str.lastIndexOf(" ");
    //   const resultArr = [];
      
    //   if (firstSpaceIndex !== -1 && lastSpaceIndex !== -1) {
    //     resultArr.push(str.substring(0, lastSpaceIndex));
    //     resultArr.push(str.substring(lastSpaceIndex + 1)); // Dodajemy fragment po ostatniej spacją
    //   }else{
    //     resultArr.push(str);
    //     resultArr.push("");
    //   }
      
    //   return resultArr; // Wyświetlamy wynik w konsoli
    // }
  
    if(this.props.pharseClientsList[1]){
      const odp = this.props.pharseClientsList[1]
      
      this.setState({first_name: odp.first_name, town: odp.town , tel: odp.tel, street: odp.street, nr_house: odp.nr_house})
      this.props.find_double_clients({first_name: odp.first_name, town: odp.town , tel: odp.tel, street: odp.street, nr_house: odp.nr_house})
    }else{
      this.props.clear_double_clients()
      this.setState({
        first_name: "",
        town: "",
        street: "",
        nr_house: "",
        tel:"",
      });
    }
    this.props.nextClientToPharse()
  };
  PharseItems = e => {
    e.preventDefault();
    const {pharse_in} = this.state
    function dec(str){
      const lastSpaceIndex = str.lastIndexOf(" ");
      const resultArr = [];
      
      if (lastSpaceIndex !== -1) {
        resultArr.push(str.substring(0, lastSpaceIndex));
        resultArr.push(str.substring(lastSpaceIndex + 1)); // Dodajemy fragment po ostatniej spacją
      }else{
        resultArr.push(str);
        resultArr.push("-");
        resultArr.push("-");
      }
      
      return resultArr; // Wyświetlamy wynik w konsoli
    }
    const isEmptyOrSpaces = (str) => {
      return str === null || str.match(/^ *$/) !== null;
    }
    const ohio =
      (pastedInput) =>{
        if(pastedInput.length == 0) return []
        let rawRows = pastedInput.split("\n");
        let headersArray = ["imie","nazwisko","miejscowosc","adres", "tel"]
        let output = [];
        rawRows.forEach((rawRow, idx) => {
          if (idx >= 0) {
            let rowObject = {};
            let values = rawRow.split("\t");
            headersArray.forEach((header, idx) => {
              rowObject[header] = values[idx] ? values[idx] : "";
            });
            output.push(rowObject);
          }
        });
        const gry = output.map((item)=>{
          const topost = dec(item.adres)
          const ifisblankbyspace = isEmptyOrSpaces(topost[1]) ? "-" : topost[1] 
          return {
            first_name: item.imie +" "+item.nazwisko,
            tel: item.tel,
            town: item.miejscowosc,
            street: topost[0],
            nr_house: ifisblankbyspace
          }
        })
        return gry;
      };
    const odp = ohio(pharse_in)
    console.log(odp)
    this.props.constructJSONFromPastedInput(odp)
    this.props.find_double_clients({first_name: odp[0].first_name, town: odp[0].town , tel: odp[0].tel, street: odp[0].street, nr_house: odp[0].nr_house})
    this.setState({first_name: odp[0].first_name, town: odp[0].town , tel: odp[0].tel, street: odp[0].street, nr_house: odp[0].nr_house})
  }
  ClearItems = e => {
    e.preventDefault()
    this.props.clearClientToPharse()
    this.props.clear_double_clients()
  }
  Dodaj = (e) => {
    e.preventDefault();
    const { name } = this.state
    const body = { name };
    this.props.add_stove(body);
    this.setState({name:""})
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { first_name, town, street,name, nr_house, tel, pharse_in } = this.state;
    
    
    return (
      <Fragment>
        <Modal show={this.state.showStove}>
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">
                  Dodaj Piec
                </h5>
                
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({showStove: false})}
                ></button>
              </div>
              <div class="modal-body">
                
              <h5>Nazwa Pieca:</h5>
              <input
              type="text"
              class="form-control"
              placeholder="nazwa"
              onChange={this.onChange}
              name="name"
              value={name}
            />
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => this.setState({showStove: false})}
                >
                  Close
                </button>
                <button 
                  type="button" 
                  class="btn btn-success"
                  onClick={this.Dodaj}
                  >
                  Dodaj
                </button>
              </div>
            </Modal>
        <h3>Dodaj Kilenta:</h3>
        <table class="table table-light">
          <tbody>
            {this.props.doubleClients.map((item)=>(
              <tr key={item.id} >
                <td style={{width: "18%"}} >{item.first_name}</td>
                <td style={{width: "18%"}}>{item.town}</td>
                <td style={{width: "18%"}}>
                  {item.street} {item.nr_house}
                </td>
                <td style={{width: "18%"}}>
                  {item.tel}
                </td>
                {item.client ? <td style={{width: "18%"}}>{item.client.data_wizyty}</td> : <td>Brak wizyt</td>}
                <td style={{width: "10%"}}>
                  <Link to="/clientDetail"><button className='btn btn-info btn-sm' onClick={() => (this.props.get_clients_detail(item.id), this.props.getClientEvents(item.id))}>Więcej</button></Link>
                </td>
            </tr>
            ))}
          </tbody>
        </table>
        <form onSubmit={this.onSubmit}>
          <div class="input-group mb-3">
          <span class="input-group-text">Imie i Naz.</span>
            <input
              type="text"
              class="form-control"
              placeholder="Imie"
              aria-label="Imie"
              onChange={this.onChange}
              name="first_name"
              value={first_name}
            />
            <span class="input-group-text">Tel:</span>
            <input
              type="text"
              class="form-control"
              placeholder="+48 "
              onChange={this.onChange}
              name="tel"
              value={tel}
            ></input>
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text">Miejscowość:</span>
            <input
              type="text"
              class="form-control"
              placeholder="Miejscowość"
              aria-label="Miejscowość"
              name="town"
              onChange={this.onChange}
              value={town}
            />
            <span class="input-group-text">Ul.:</span>
            <input
              type="text"
              class="form-control"
              placeholder="Ulica"
              aria-label="Ulica"
              name="street"
              onChange={this.onChange}
              value={street}
            />
            <span class="input-group-text">Nr:</span>
            <input
              type="text"
              class="form-control"
              placeholder="--"
              onChange={this.onChange}
              name="nr_house"
              value={nr_house}
            ></input>
            
          </div>
          <h6>Piec:</h6>
          <div class="input-group mb-3">
          <select
                class="form-control"
                onChange={this.onChange}
                id="exampleFormControlSelect1"
                name="member"
              >
                <option value={null}>
                  Brak Pieca
                </option>
                {this.props.stove_list.map((stove) => (
                  <option key={stove.id} value={stove.id}>
                    {stove.name}
                  </option>
                ))}
            </select>
            <button className="btn btn-success" onClick={() => this.setState({showStove: true})}>
              Dodaj Piec
            </button>
            </div>
          <div className="form-group">
            <button type="submit" className="btn btn-success">
              Dodaj Klienta
            </button>
            <button onClick={this.Next} className="btn btn-primary">
              Pomiń klienta
            </button>
          </div>
        </form>
        <table class="table table-dark">
        {this.props.pharseClientsList[0] ?
          <thead>
            <tr>
              <th scope="col">KLIENT</th>
              <th scope="col">ADRES</th>
              <th scope="col">TEL</th>
              <th scope="col"></th>
            </tr>
          </thead>
          : null }
          
          {this.props.pharseClientsList[0] ?
          <tbody>
            <tr class="table-primary">
              <td>{this.props.pharseClientsList[0].first_name}</td>
              <td>{this.props.pharseClientsList[0].town} {this.props.pharseClientsList[0].street} {this.props.pharseClientsList[0].nr_house}</td>
              <td>{this.props.pharseClientsList[0].tel}</td>
              <td>{this.props.pharseClientsList[0].powtarza ? <span class="dot"></span> : null } </td>
            </tr>
            
            {this.props.pharseClientsList.slice(1).map((element)=>(
              <tr>
                <td>{element.first_name}</td>
                <td>{element.town} {element.street} {element.nr_house}</td>
                <td>{element.tel}</td>
                <td>{element.powtarza ? <span class="dot"></span> : null }</td>
              </tr>
            ))}
          </tbody>
          :
          null }
          
        </table>
        <textarea 
          class="form-control"
          onChange={this.onChange}
          name="pharse_in"
          value={pharse_in}
        ></textarea>
        <button className="btn btn-primary" onClick={this.PharseItems}>
          Konwertuj
        </button>
        <button onClick={this.ClearItems} className="btn btn-danger">
          Wyczyść
        </button>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  stove_list: state.stoves.stove_list,
  pharseClientsList: state.clients.pharseClientsList,
  doubleClients: state.clients.doubleClients,
});


export default connect(mapStateToProps, { addClient ,get_clients_detail, add_stove , get_stove, constructJSONFromPastedInput, nextClientToPharse, clearClientToPharse, find_double_clients, clear_double_clients })(withStyles(useStyles)(Group));
