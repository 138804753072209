import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { Redirect } from "react-router-dom";
// import Header from "../layout/Header";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { changePasswd } from "../actions/auth";

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class ChangePasswd extends Component {
  state = {
    old_password: "",
    new_password: "",
    new_password2: "",
  };
  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.new_password == this.state.new_password2) {
      this.props.changePasswd(
        this.state.old_password,
        this.state.new_password2
      );
    } else {
      console.log("złe chasło");
    }
    this.setState({
      old_password: "",
      new_password: "",
      new_password2: "",
    });
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  render() {
    const { new_password, old_password, new_password2 } = this.state;

    return (
      <div className="col-md-6 m-auto">
        <div className="card card-body mt-5">
          <h2 className="text-center">Login</h2>
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label>Obecne chasło</label>
              <input
                type="password"
                className="form-control"
                name="old_password"
                onChange={this.onChange}
                value={old_password}
              />
              <label>Nowe chasło</label>
              <input
                type="password"
                className="form-control"
                name="new_password"
                onChange={this.onChange}
                value={new_password}
              />
              <label>powtórz Nowe chasło</label>
              <input
                type="password"
                className="form-control"
                name="new_password2"
                onChange={this.onChange}
                value={new_password2}
              />

              <button type="submit" className="btn btn-primary">
                Zmień chasło
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { changePasswd })(
  withStyles(useStyles)(ChangePasswd)
);
